/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import auditless from '../images/auditless.svg'
import ethereumIcon from '../images/ethereum-icon-black.svg'
import twitter from '../images/twitter-icon.svg'
import "./layout.css"
import "./custom.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div class="md:flex">
      <div class="md:w-1/5"></div>
      <div class="override-white overflow-hidden md:fixed elevated-border h-full sm:w-full md:w-1/5 black-border">
        <div class="h-16 elevated-border flex justify-center items-center">
          <a target="_blank" href="http://www.auditless.com"><img class="h-3 m-0" src={auditless} /></a>
        </div>
        <div class="top-border">
          <div class="m-6">
            <ul class="nav">
              <li class="font-bold uppercase"><Link to="/"><img class="ethicon w-4 float-left" src={ethereumIcon} /> Ethereum Reference</Link></li>
              <li class="flex"><div class="w-4/5"><Link to="/cheatsheet/">Solidity Cheat Sheet</Link></div><div class="badge">NEW</div></li>
              <li class="flex"><div class="w-4/5"><Link to="/cheatsheet/">Vyper Cheat Sheet</Link></div><div class="badge">NEW</div></li>
            </ul>
          </div>
        </div>
        <div class="plastic plastic-ball hidden md:block"></div>
      </div>
      <div class="absolute top-0 md:bottom-0 md:top-auto md:fixed mt-4 md:mt-0 twitter">
        <OutboundLink target="_blank" href="http://www.twitter.com/auditless"><img class="h-8" src={twitter} /></OutboundLink>
      </div>
      <div class="w-full md:w-4/5">
        <div class="w-full md:h-32 h-16 mb-1 plastic">
        </div>
        <div class="top-border"></div>
        <div class="flex mt-16">
          <div class="w-1/12"></div>
          <div class="w-10/12">
            <main>{children}</main>
            <footer class="mt-10">
              © {new Date().getFullYear()}, <a href="http://www.auditless.com">Auditless</a>
            </footer>
          </div>
          <div class="w-1/12"></div>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
